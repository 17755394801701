import React from 'react';
import { Image } from 'react-bootstrap';
// import './Verweise.css';

const Home = () => {
  return (
    <Image src="/bilder/home.png" fluid/>
  ) 
}

export default Home;
