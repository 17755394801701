import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { getSoundUrl } from './soundsSlice';
import Musicbutton from './Musicbutton.js';

const mystyle = {
  minWidth: "240px"
}
  
const Musik = () => { 
    return (
      <Container fluid>
        <Row md={1}>
          <Col>
            <Image fluid style={mystyle} src={getSoundUrl(2) + '.jpg'} />
            <Musicbutton onimage musik="2" />
          </Col>
          <Col>
            <Image fluid style={mystyle} src={getSoundUrl(3) + '.jpg'} />
            <Musicbutton onimage musik="3" />
          </Col>
        </Row>
        <Row>
          <Col align="center">
          </Col>
        </Row>
      </Container>
  ) 
}

export default Musik;
