import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSound, getSoundTitle } from './soundsSlice';
import './Musicbutton.scss'

const Musicbutton = (props) => {

  const sounds = useSelector( state => state.sounds )
  const dispatch = useDispatch()

  const imgStart='../bilder/Start1.png'  // SpeakerOn.png
  const imgPause='../bilder/Pause1.png'    // SpeakerOff.png
  const btnstyle = ( props.onimage ? "onimage" : "inline" )

  let pl = {
    musik: props.musik,
    muted: ! sounds.playerMuted
  }

  let image = sounds.playerMuted ? imgStart : imgPause
  if (sounds.aktMusik !== props.musik) image = imgStart

  return (
    <img
      src= {image} 
      alt= ''
      title= {getSoundTitle(props.musik)}
      class = {btnstyle}
      onClick = { () => dispatch( setSound({pl}) ) }   
    />
  )
} 

export default Musicbutton
