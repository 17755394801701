import React from 'react';
import { Image } from 'react-bootstrap';

const Impressum = () => {
  return (
    <Image src="/bilder/impressum.png" fluid/>
  ) 
}

export default Impressum;
