import React from 'react';
import { Container } from 'react-bootstrap';
import { HashRouter } from "react-router-dom";
import Laufschrift from './Laufschrift.js';
import Navigation from './Navigation.js';
import Content from './Content.js'
import Player from './Player.js';
import './App.scss';

export default function App() {

  return(
    <Container fluid>
      <Laufschrift idx='0' />
      <HashRouter>
        <Navigation />
        <Content />
      </HashRouter>
      <Laufschrift idx='1' />
      <Player />
    </Container>
  )
}
