import { createSlice } from '@reduxjs/toolkit'

const basePath = 'https://upload.galaorchester.at/'
export const Musikliste = [
  { url: '', title: '' },
  { url: basePath+'Caressing', title: 'Caressing' },
  { url: basePath+'MoonlightSerenade', title: 'Moonlight Serenade' },
  { url: basePath+'ComeandchillinAustria', title: 'Come and Chill in Austria' }
]

const initialState = {
  aktMusik: 0,
  playerMuted: true
}

const soundsSlice = createSlice({
  name: 'sounds',
  initialState, 
  reducers: {
    setSound: (state, action) => {
      state.aktMusik = action.payload.pl.musik
      state.playerMuted = action.payload.pl.muted
    }
  }
})

export const getSoundTitle = (idx) => { return Musikliste[idx].title }
export const getSoundUrl = (idx) => { return Musikliste[idx].url }

export const {setSound} = soundsSlice.actions
export default soundsSlice.reducer
