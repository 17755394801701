import React from 'react';
import { Image } from 'react-bootstrap';

const Orchester = () => {
  return (
    <Image src="/bilder/orchester.jpg" fluid />
  ) 
}

export default Orchester;
