import React, { Component } from 'react';
import { Container, Form, Button, Row, Col, Image, Modal } from 'react-bootstrap';
import axios from 'axios';
import './Kontakt.scss';

class Kontakt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      absender: '',
      betreff: '',
      nachricht: '',
      empfaenger: 'office@galaorchester.at',
      mailSent: false,
      error: null,
      modus: 3,    // 1 = nur Entwickler, 2 = +Absender, 3 = alle, 4 = ohne Entwickler
      showModal: false
    }
  }

  handleFormSubmit( event ) {
    event.preventDefault();
    axios.post( 'https://mailer.web66.info', this.state )
        .then( result => { this.setState( {
            mailSent: result.data.sent,
            error: result.data.message } )
        })
        .catch( error => this.setState( { error: error.message } ) );
    this.setState({showModal:true});
  };

  render() {
    return (
      <Container fluid>
        <Row className="MeineRow" md={1}>
          <Col className="Meintext">
            Gerne würden wir mit Ihnen, in einem persönlichen Gespräch,
            Ihre individuellen Anforderungen im Detail besprechen! <br /><br />
            Ihr Ansprechpartner: <br />
            <b>Kplm. Harald Lakits</b> <br />
            Tel. +43 - (0)664 / 34 62 418
          </Col>
          <Col className="Meinbild">
            <Image src="/bilder/home.png" rounded fluid/>
          </Col>
        </Row>
        <Row className="MeineRow" ><Col>
          <Form className="Meinform">
              <Form.Label>Ihre Nachricht an uns:</Form.Label>
              <Form.Control type="email" required
                  placeholder={ 'Ihre Mailadresse...' }
                  onChange = {e=>this.setState({ absender:e.target.value })} />
              <Form.Control type="text" required
                  placeholder={ 'Ihr Betreff...' }
                  onChange = {e=>this.setState({ betreff:e.target.value })} />
              <Form.Control as="textarea" rows="3" required
                  placeholder={ 'Ihre Nachricht...' }
                  onChange = {e=>this.setState({ nachricht:e.target.value })} />
              <Button type="submit" onClick={e=>this.handleFormSubmit(e)} >
                  Absenden
              </Button>
              <Modal show={this.state.showModal} size="sm" 
                onHide={e=>this.setState({showModal:false})}>
                <Modal.Header closeButton>
                  <Modal.Title>Danke für Ihre Nachricht!</Modal.Title>
                </Modal.Header>
              </Modal>
          </Form>
        </Col></Row>
      </Container>
    )
  }
}

export default Kontakt;
