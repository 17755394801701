import React from 'react';
import { useSelector } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';
import { getSoundUrl } from './soundsSlice';


const Player = () => {
  const sounds = useSelector( state => state.sounds )

  return (
    <ReactAudioPlayer
      src = { getSoundUrl( sounds.aktMusik ) + '.mp3'}
      muted = {sounds.playerMuted}
      autoPlay 
      loop
//      controls
    />
  ) 
}

export default Player
