import React from 'react';
import { Container } from 'react-bootstrap';
import { Route } from "react-router-dom";
import Home from './Home';
import Orchester from './Orchester';
import Musik from './Musik';
import Kontakt from './Kontakt';
import Impressum from './Impressum';

const mystyle = {
  backgroundColor: "#003366",
  padding: "0.3em 0"
}

const Content = () => {
  return (
    <Container style={mystyle} fluid>
      <Route path="/" component={Home} exact />
      <Route path="/orchester/" component={Orchester} />
      <Route path="/musik/" component={Musik} />
      <Route path="/kontakt/" component={Kontakt} />
      <Route path="/impressum/" component={Impressum} />
    </Container>
  ) 
}

export default Content
