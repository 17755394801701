import React from 'react'
import Navbar from 'react-bootstrap/navbar'
import Nav from 'react-bootstrap/nav'
import { LinkContainer } from 'react-router-bootstrap'
import Musicbutton from './Musicbutton.js'
// import './Navigation.scss'

const mystyle = {
  margin: "0"
}

const Navigation = () => {
  return (
    <Navbar collapseOnSelect sticky='top'
      style={mystyle} variant="dark" bg="secondary" 
      expand="md" >
      <Navbar.Brand href="/">Galaorchester Harald Lakits</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav variant='pills' className="mr-auto">
          <NavItem path="/orchester" caption="Orchester" />
          <NavItem path="/musik" caption="Musik" />
          <NavItem path="/kontakt" caption="Kontakt" />
          <NavItem path="/impressum" caption="Impressum" />
        </Nav>
      </Navbar.Collapse>
      <Musicbutton musik="1" />
    </Navbar>
  ) 
}

const NavItem = (props) => {
  return (
    <LinkContainer to={props.path}> 
      <Nav.Link> {props.caption} </Nav.Link> 
    </LinkContainer>
  )
}

export default Navigation;
