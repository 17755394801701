import React from 'react';
import { Container } from 'react-bootstrap';
import Marquee from 'react-double-marquee';
import './Laufschrift.scss';

const spaces = "\u00A0\u00A0\u00A0\u00A0\u00A0"

const texte = [
  ['Willkommen!' + spaces + 'Bienvenu!' + spaces + 'Welcome!' + spaces + 'Bienvenido!' + spaces,
    '---' + spaces + 'GALAORCHESTER HARALD LAKITS' + spaces + '---' + spaces ],
  ['Aus urheberrechtlichen Gründen verwenden wir auf unserer Homepage nur eigene Werke!' + spaces,
    '---' + spaces + 'GALAORCHESTER HARALD LAKITS' + spaces + '---' + spaces]
]

const Laufschrift = ( props ) => {
  return (
    <Container className="myMarquee" fluid>
      <Marquee
        speed="0.1" delay="0" direction="left" 
        childMargin="0" children = {texte[props.idx]} 
      />
    </Container>
  )
}

export default Laufschrift
